import React, { Component } from "react";
import config from "../../config/config";
import PropTypes from "prop-types";

const { cloudinary } = config;

class Photo extends Component {
  urlTransform = img => {
    const { width, height, crop, quality } = this.props;

    return `${cloudinary.baseUrl}/${
      cloudinary.widget.cloudName
    }/image/upload/f_auto,q_auto/c_${crop},h_${height},q_${quality},w_${width}/v1/${
      img.publicId
    }.jpg`;
  };

  render() {
    let { img, className, onClick, title } = this.props;
    let extra = {};
    if (!title) {
      title = "";
    }
    if (title.length < 2) {
      title = img.title;
    }
    if (onClick) {
      extra.onClick = onClick;
    }
    return (
      <img
        src={this.urlTransform(img)}
        alt={img.title}
        className={className}
        title={title}
        {...extra}
      />
    );
  }
}

Photo.propTypes = {
  img: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  quality: PropTypes.number,
  crop: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
};

Photo.defaultProps = {
  quality: cloudinary.quality,
  crop: "fit",
  className: "photo",
  title: ""
};

export default Photo;
