import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

class Paginator extends Component {
  state = {
    pageLinks: []
  };

  componentDidMount = () => {
    this.buildLinks()
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page !== this.props.page || prevProps.numPages !== this.props.numPages) {
      this.buildLinks();
    }
  }

  buildLinks = () => {
    const { linkPath, numPages, page } = this.props;
    const showPrev = page > 0
    const lastIndex = numPages -1
    const showNext = page < lastIndex
    let pLinks = [];
    if (numPages > 1) {
      if (showPrev) {
        pLinks.push({
          label: <Icon className="angle left" />,
          current: false,
          path: `${linkPath}?page=${(page-1)}`
        });
      }
      for (let i = 0; i < numPages; i++) {
        pLinks.push({
          label: i + 1,
          current: i === page,
          path: `${linkPath}?page=${i}`
        });
      }
      if (showNext) {
        pLinks.push({
          label: <Icon className="angle right" />,
          current: false,
          path: `${linkPath}?page=${(page+1)}`
        });
      }
      this.setState({
        pageLinks: pLinks
      });
    }
  }

  render() {
    const { pageLinks } = this.state;
    return (
      <ol className="paginator">
        {pageLinks.map((lp, index) => (
          <li className={`index-${index}`} key={index}>
            {lp.current ? (
              <strong>{lp.label}</strong>
            ) : (
              <Link to={lp.path}>{lp.label}</Link>
            )}
          </li>
        ))}
      </ol>
    );
  }
}

export default Paginator;
