import React from "react";
import { Container, Table } from "semantic-ui-react";
import { getStats } from "../../modules/api";

class Landing extends React.Component {
  state = {
    title: "Image Moderation",
    subtitle: "Welcome to the Image Moderation portal",
    text: "Your are now logged in",
    statItems: []
  };
  componentDidMount = async () => {
    getStats().then(data => {
      if (data.valid) {
        this.setState({
          statItems: data.items
        });
      }
    });
  };

  render() {
    const { title, subtitle, text, statItems } = this.state;
    const hasStats = statItems.length > 0
    const stats = statItems.filter(stat => stat.val > 0).map(stat => {
      let txt = stat.key.replace(/-+/g, ' ')
      stat.label = txt.charAt(0).toUpperCase() + txt.slice(1);
      return stat
    })
    return (
      <Container className="main-content">
        <h1 className="title">{title}</h1>
        <article className="text-content">
          <h2 className="subtitle">{subtitle}</h2>
          <div className="body">{text}</div>
          <Table className="stats" celled>
            <Table.Body>
              {hasStats &&
                stats.map((stat, index) => (
                  <Table.Row key={index}>
                    <Table.HeaderCell>{stat.label}</Table.HeaderCell>
                    <Table.Cell>{stat.val}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </article>
      </Container>
    );
  }
}

export default Landing;
