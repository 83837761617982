import axios from "axios";
import config from "../config/config";
import { fromLocal, toLocal } from "./localstore";
import { matchInt } from "./utils";

const postData = async (path, params, putMode) => {
  let result = {};
  let func = putMode !== true ? axios.post : axios.put;
  await func(`${config.backendApi}${path}`, params, {
    headers: {
      apikey: config.apiKey
    }
  }).then(res => {
    result = res;
  });
  return result;
};

const putData = async (path, params) => {
  return postData(path, params, true);
};

const fetchData = async (path, deleteMode) => {
  let result = {};
  let func = deleteMode !== true ? axios.get : axios.delete;
  await func(`${config.backendApi}${path}`, {
    headers: {
      apikey: config.apiKey
    }
  }).then(res => {
    result = res;
  });
  return result;
};

const deleteData = async path => {
  return fetchData(path, true);
};

export const extractPublicId = url => {
  let pId = ''
  let hasPublicId = /cloudinary/.test(url)
  if (hasPublicId) {
    let parts = url.split('/')
    if (parts.length > 1) {
      let imgN = parts.pop().replace(/\.\w+$/, '')
      pId = parts.pop() + '/' + imgN
    }
  }
  return pId
}

export const fetchImages = async (status, start, limit, category) => {
  let data = {
    items: [],
    num: 0
  };
  start = matchInt(start, 0);
  limit = matchInt(limit, config.gallery.perLoad);
  if (!status) {
    status = "all";
  }
  let extraParams = [start, limit, status];
  if (category) {
    extraParams.push(category);
  }
  let paramPath = extraParams.join("/");
  await fetchData(`admin/works/${paramPath}`)
    .then(res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data;
        }
      }
    })
    .catch(e => {
      data.error = e;
    });
  return data;
};

export const fetchTags = async () => {
  let data = {
    items: [],
    num: 0
  };
  const stored = fromLocal("tag-list", 300);
  if (stored.valid && config.localStoreEnabled) {
    data = stored.data;
  } else {
    await fetchData(`admin/tags`).then(res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data;
        }
      }
    });
    if (data.items) {
      toLocal("tag-list", data);
    }
  }
  return data;
};

export const fetchLikes = async () => {
  let data = {
    items: [],
    num: 0
  };
  let max = 50;
  let cKey = "like-list-full--" + max;
  const stored = fromLocal(cKey, 600);
  if (stored.valid && config.localStoreEnabled) {
    data = stored.data;
  } else {
    await fetchData(`like-list-full/${max}`).then(res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data;
        }
      }
    });
    if (data.items) {
      toLocal(cKey, data);
    }
  }
  return data;
};

export const fetchUsers = async () => {
  let data = {
    items: [],
    num: 0
  };
  await fetchData(`/admin/users`).then(res => {
    if (res.data.items) {
      if (res.data.items instanceof Array) {
        data = res.data;
      }
    }
  });
  return data;
};

export const matchUser = async (identifier) => {
  let data = {
    identifier: '',
    _id: '',
    valid: false
  };
  await fetchData(`/admin/user/match/${identifier}`).then(res => {
    if (res.data.identifier) {
      data = res.data;
      data.valid = true;
    }
  });
  return data;
};

export const fetchArtists = async (start, limit, filter) => {
  let data = {
    items: [],
    num: 0
  };
  start = matchInt(start, 0);
  limit = matchInt(limit, 100);
  let filterSuffix = "";
  if (filter) {
    if (typeof filter === "string") {
      if (filter.length > 1) {
        filterSuffix = "/" + filter;
      }
    }
  }
  await fetchData(`admin/artists/${start}/${limit}${filterSuffix}`).then(
    res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data;
        }
      }
    }
  );
  return data;
};

export const saveUser = async (user, adminMode) => {
  let data = {
    valid: false
  };
  if (!adminMode) {
    adminMode = false;
  }
  let path = "";
  if (adminMode) {
    path += "admin/";
  }
  path += "user/save";
  if (user._id && adminMode) {
    if (typeof user._id === "string" && user._id.length > 5) {
      path = "user/edit/" + user._id;
    }
  }
  await postData(path, user).then(res => {
    if (res.data) {
      if (res.data.identifier) {
        data = res.data;
        data.valid = true;
        if (!adminMode) {
          if (data.status > 0) {
            const cKey = "current-user";
            toLocal(cKey, data);
          }
        }
      }
    }
  });
  return data;
};

export const postImage = async (info, mode, callback) => {
  let data = {
    valid: false
  };

  switch (info.format) {
    case "jpg":
      info.mime = "image/jpeg";
      break;
  }
  switch (mode) {
    case "upload":
      data = {
        uri: info.secure_url,
        mimetype: info.mime,
        filesize: info.bytes,
        width: info.width,
        height: info.height,
        caption: info.caption,
        description: "",
        tags: [],
        source: "cloudinary",
        status: "uploaded",
        user: info.user
      };
      break;
  }
  await putData(`work/save`, data).then(res => {
    if (res.data) {
      if (res.data.uri) {
        handleImageData(res.data, true, callback);
      }
    }
  });
  return data;
};

const handleImageData = (data, isNew, callback) => {
  if (data.uri) {
    if (callback) {
      setTimeout(() => {
        callback({ success: true, isNew: isNew, image: data });
      }, 100);
    }
  }
};

export const updateImage = async (id, params, callback) => {
  let data = {
    valid: false
  };
  await postData(`work/edit/${id}/admin`, params).then(res => {
    if (res.data) {
      if (res.data.uri) {
        handleImageData(res.data, false, callback);
      }
    }
  });
  return data;
};

export const updateImageStatus = async (idRef, status) => {
  let data = {
    valid: false
  };
  let params = {
    status: status
  };
  let path = "";
  if (idRef instanceof Array) {
    path = `admin/work/status`;
    params.ids = idRef;
  } else if (typeof idRef === "string") {
    path = `work/status/${idRef}`;
  }
  await postData(path, params).then(res => {
    if (res.data) {
      if (res.data.valid) {
        data = res.data;
        data.valid = true;
      }
    }
  });
  return data;
};

export const deleteImage = async id => {
  let data = {
    valid: false
  };
  await deleteData(`work/delete/${id}`)
    .then(res => {
      if (res.data) {
        data = res.data;
        data.id = id;
        data.valid = true;
      }
    });
  return data;
};

export const getStats = async () => {
  let data = {
    valid: false
  };
  await fetchData(`admin/stats`)
    .then(res => {
      if (res.data) {
        if (res.data.valid) {
          data = res.data;
        }
      }
    });
  return data;
};

export const saveProfile = async user => {
  let data = {
    valid: false
  };
  await postData(`user/save-profile`, user).then(res => {
    if (res.data) {
      if (res.data.identifier) {
        data = res.data;
        data.valid = true;
      }
    }
  });
  return data;
};

export const fetchUser = async () => {
  const cKey = "admin-user";
  const stored = fromLocal(cKey, 86400);
  let data = {
    valid: false
  };
  if (stored.valid) {
    data = stored.data;
  }
  return data;
};

export const authenticate = async (identifier, password) => {
  let data = {
    valid: false
  };
  let params = {
    identifier,
    password
  };
  await postData('user/authenticate', params)
    .then(response => {
      if (response.data) {
        const user = response.data;
        if (user.identifier) {
          if (user.status > 0) {
            const cKey = "admin-user";
            data = user;
            data.valid = true;
            toLocal(cKey, data);
          }
        }
      }
    });
  return data;
};

export const fetchCurations = async () => {
  let data = {
    items: [],
    num: 0
  };
  await fetchData('admin/curations').then(res => {
    if (res.data.items) {
      if (res.data.items instanceof Array) {
        data = res.data;
      }
    }
  });
  return data;
};

export const saveCuration = async params => {
  let data = {
    valid: false
  };
  await postData('admin/curation/save', params)
    .then(res => {
      if (res.data._id) {
        data = res.data;
        data.valid = true;
      }
    });
  return data;
};

export const addRemoveImageToCuration = async (curId, imgId, remove) => {
  let data = {
    valid: false
  };
  let params = {
    id: curId,
    imgId: imgId
  };
  let mode = remove === true ? "remove" : "add";
  await postData(`admin/curation/${mode}-image`, params)
    .then(res => {
      if (res.data._id) {
        data = res.data;
        data.valid = true;
      }
    });
  return data;
};

export const fetchCuration = async id => {
  let data = {
    valid: false
  };
  await fetchData(`admin/curation/${id}`)
    .then(res => {
      if (res.data.title) {
        data = res.data;
        data.valid = data.images instanceof Array;
      }
    });
  return data;
};

export const deleteCuration = async id => {
  let data = {
    valid: false
  };
  await deleteData(`admin/curation/delete/${id}`)
    .then(res => {
      if (res.data) {
        data = res.data;
      }
    });
  return data;
};
