import React from "react";
import { render } from "react-dom";
import { Router } from "react-router";
import createHistory from "history/createBrowserHistory";
import App from "./containers/app/index";
import "semantic-ui-css/semantic.min.css";

import "sanitize.css/sanitize.css";
import "./index.css";

export const history = createHistory();
const target = document.querySelector("#root");

render(
  <Router history={history}>
    <App />
  </Router>,
  target
);
