import React from "react";
import { Icon } from "semantic-ui-react";
import { updateImage, matchUser } from "../../modules/api";
import { WithContext as ReactTags } from "react-tag-input";

class ImageEdit extends React.Component {
  state = {
    currId: "",
    caption: "",
    description: "",
    placename: "",
    suggestions: [],
    selectedTags: [],
    user: {
      _id: '',
      identifier: '',
      displayName: ''
    },
    identifier: ''
  };

  componentDidMount = async () => {
    const { tags, img } = this.props;
    if (img) {
      let st = {};
      if (img._id) {
        st.currId = img._id;
        if (img.caption) {
          st.caption = img.caption.replace(/^untitled\.\.+$/, '');
        }
        if (img.description) {
          st.description = img.description;
        }
        if (img.location) {
          if (img.location.placename) {
            st.placename = img.location.placename;
          }
        }
        if (img.tags) {
          st.selectedTags = [];
          if (img.tags.length > 0) {
            let sTags = img.tags.filter(t => {
              if (t.hasOwnProperty('_id')) {
                return t._id.length > 5
              } else {
                return false
              }
            }).map(t => {
              return { id: t._id, text: t.name };
            });
            st.selectedTags = sTags.filter(t => t.id.length > 5 && t.text.length > 1)
          }
        }

        if (img.user) {
          if (img.user.identifier) {
            st.user = img.user;
            st.identifier = st.user.identifier;
          }
        }
      }
      this.setState(st);
    }
    if (tags instanceof Array) {
      let suggestions = tags.map(t => {
        return { id: t._id, text: t.name };
      });
      this.setState({
        suggestions: suggestions
      });
    }
  };

  updateImageData = () => {
    let { currId, selectedTags, caption, description, placename, user } = this.state;
    if (currId.length > 3) {
      caption = caption.trim();
      description = description.trim();
      let data = {
        caption: caption,
        description: description,
        tags: selectedTags.map(t => t.text)
      };
      if (placename) {
        data.location = {
          placename: placename
        };
      }
      if (user) {
        if (user._id) {
          data.user = user._id;
        }
      }
      updateImage(currId, data, response => {
        if (response.success) {
          data._id = currId
          this.props.updateImage(data, user)
        }
      });
    }
  };

  selectTag = tag => {
    let tags = this.state.selectedTags;
    let newTag = null;
    if (typeof tag == "string") {
      newTag = {
        id: tag,
        text: tag.trim()
      };
    } else if (tag !== null) {
      newTag = tag;
    }
    if (newTag) {
      if (newTag.text.length > 2) {
        tags.push(newTag);
        this.setState({ selectedTags: tags });
      }
    }
  };

  handleDrag = (tag, currPos, newPos) => {
    let tags = this.state.selectedTags;
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);
    this.setState({ selectedTags: tags });
  };

  deleteTag = async tagIndex => {
    if (typeof tagIndex === "number" && tagIndex >= 0) {
      let selTags = this.state.selectedTags;
      if (tagIndex < selTags.length) {
        selTags.splice(tagIndex, 1);
        this.setState({ selectedTags: selTags });
      }
    }
  };

  updateValue = e => {
    if (e.target.name) {
      let nm = e.target.name;
      let params = {};
      params[nm] = e.target.value;
      this.setState(params);
    }
  };

  updateIdentifier = async e => {
    if (e.target.name) {
      let identifier = e.target.value;
      let st = { identifier: identifier };

      if (identifier.length > 5 && /[^@]*\w[^@]*@\w+/.test(identifier)) {
        await matchUser(identifier).then(user => {
          if (user.identifier) {
            if (user.status > 0) {
              st.user = user;
            }
          }
        })
      }
      this.setState(st);
    }
  }

  render() {
    const {
      selectedTags,
      caption,
      description,
      placename,
      suggestions,
      user
    } = this.state;
    let { identifier } = this.state;
    return (
      <div className="edit-panel">
        <ReactTags
          tags={selectedTags}
          suggestions={suggestions}
          className="textfield textfield-long"
          handleDelete={this.deleteTag}
          handleAddition={this.selectTag}
          handleDrag={this.handleDrag}
          handleInputBlur={this.selectTag}
          inline
        />
        <input
          name="caption"
          type="text"
          value={caption}
          minLength={2}
          size={64}
          placeholder="Brief caption"
          required
          className="textfield textfield-long"
          onChange={this.updateValue}
        />
        <textarea
          name="description"
          type="text"
          rows={2}
          cols={64}
          placeholder="Description"
          className="textfield textarea"
          value={description}
          onChange={this.updateValue}
        />
        <input
          name="placename"
          type="text"
          value={placename}
          minLength={2}
          size={64}
          placeholder="Location"
          required
          className="textfield textfield-long"
          onChange={this.updateValue}
        />
        <input
          name="identifier"
          type="email"
          value={identifier}
          minLength={5}
          size={128}
          placeholder="Attribution"
          required
          className="textfield textfield-long"
          onChange={this.updateIdentifier}
        />
        <p><span className="display-name">{user.displayName}</span> (<em className="user-role">{user.role}</em> ) </p>
        <div className="actions">
          <Icon className="save" onClick={this.updateImageData}>
            <span className="label">Save</span>
          </Icon>
        </div>
      </div>
    );
  }
}

export default ImageEdit