import React, { Component } from 'react'
import MenuListItem from './menu-list-item'
import { Icon} from 'semantic-ui-react'

class MenuList extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { items, current, className } = this.props
    const hasItems = items instanceof Array && items.length > 0
    let cls = ["menu"]
    if (className) {
      cls.push(className)
    }
    
    const listClasses = cls.join(' ')
    return (
      <ul className={listClasses}>
        {hasItems &&
          items.map((item, index) => (
            <MenuListItem to={item.to} current={current} key={index}>
              {item.hasContent && item.content}
              {item.hasLabel && <span className={item.hasLoggedinLabel? `logged-out` : `label`}>{item.label}</span>}
              {item.hasLoggedinLabel && <span className="logged-in">{item.loggedinLabel}</span>}
              {item.hasIcon && <Icon className={item.icon} />}
            </MenuListItem>
          ))}
      </ul>
    )
  }
}

export default MenuList
