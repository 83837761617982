import React from "react";
import { Segment, Container, Table, Icon } from "semantic-ui-react";
import { fetchTags } from "../../modules/api";
import { Link } from 'react-router-dom'

class TagList extends React.Component {
  state = {
    title: "Tags",
    tags: [],
    numTags: 0,
    numUsedTags: 0
  };

  async componentDidMount() {
    this.loadTags();
  }

  loadTags = async () => {
    let data = await fetchTags();
    if (data.items) {
      this.setState({
        tags: data.items.sort((a,b) => b.numImages - a.numImages),
        numTags: data.items.length
      });
    }
  }

  render() {
    const { title, tags, numTags } = this.state;
    return (
      <Container className="users-artists">
        <Segment.Group>
          <h2>Tags</h2>
          {numTags > 0 && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Tag name</Table.HeaderCell>
                  <Table.HeaderCell># images</Table.HeaderCell>
                  <Table.HeaderCell>View</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tags.map((tag, index) => (
                  <Table.Row key={index}>
                    <Table.HeaderCell>{tag.name}</Table.HeaderCell>
                    <Table.Cell>{tag.numImages}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/images/all/${tag.slug}`}><Icon className="picture"></Icon></Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Segment.Group>
        
      </Container>
    );
  }
}

export default TagList