import React from 'react'
import { Container, Icon } from 'semantic-ui-react'
import { fetchUser } from '../../modules/api'
import {toLocal} from '../../modules/localstore'

class UserWidget extends React.Component {
  
  state = {
    id: 0,
    loggedIn: false,
    role: 'none',
    status: 0,
    displayName: '',
    identifier: ''
  }

  componentDidMount = async () => {
    let matchedUser = await fetchUser()
    if (matchedUser) {
      if (matchedUser.identifier) {
        this.setUserData(matchedUser, false);
      }
    }
  }

  logOut = async () => {
    const params = {
      id: 0,
      identifier: '',
      displayName: '',
      role: 'none',
      loggedIn: false
    }
    this.setState(params)
    toLocal('admin-user', {loggedIn:false})
    this.props.registerLogout()
  }

  setUserData = (matchedUser) => {
    let params = {
      id: matchedUser._id,
      status: matchedUser.status,
      loggedIn: true,
      identifier: matchedUser.identifier,
      loginMode: matchedUser.loginMode,
      role: matchedUser.role,
      displayName: matchedUser.displayName
    };
    this.setState(params);
  }

  userData = () => {
    return this.state;
  }

  render() {
    const { outerClass, role, identifier, displayName, loggedIn } = this.state
    return (
      <Container className={outerClass}>
        {loggedIn &&
          <span className="user-details">
            <span className="display-name" title={`${role}: ${identifier}`}>{displayName}</span>
            <Icon className="log out" onClick={() => this.logOut()} title="Log out" />
        </span>}
      </Container>
    )
  }
}

export default UserWidget