import React from "react";
import { Icon } from "semantic-ui-react";

class UserEdit extends React.Component {
  state = {
    isNew: false,
    mayEditEmail: false,
    id: "",
    identifier: "",
    loginMode: "",
    password: "",
    cPassword: "",
    displayName: "",
    role: "",
    status: 0,
    profile: {
      firstName: "",
      lastName: ""
    },
    showError: false,
    errorMsgs: []
  };

  componentDidMount = async () => {
    let { user } = this.props;

    if (user) {
      let keys = Object.keys(user);
      let st = {
        isNew: true
      };

      for (let i = 0; i < keys.length; i++) {
        let k = keys[i];
        switch (k) {
          case "_id":
            st.id = user._id;
            st.isNew = false;
            break;
          case "identifier":
            st[k] = user[k];
            break;
          case "role":
          case "displayName":
          case "loginMode":
            st[k] = user[k];
            break;
          case "status":
            st[k] = parseInt(user[k]);
            break;
        }
      }
      if (!st.role) {
        st.role = st.isNew ? "curator" : "contributor";
      }
      if (st.role) {
        st.mayEditEmail = st.role !== "contributor";
      }
      if (st.isNew) {
        st.status = 1;
      }
      this.setState(st);
    }
  };

  setUserData = (matchedUser, loginMode) => {
    let params = {
      id: matchedUser._id,
      status: matchedUser.status,
      loggedIn: true,
      identifier: matchedUser.identifier,
      loginMode: matchedUser.loginMode,
      role: matchedUser.role,
      displayName: matchedUser.displayName
    };
    this.setState(params);
  };

  updateValue = e => {
    if (e.target.name) {
      let nm = e.target.name;
      let params = {};
      params[nm] = e.target.value;
      this.setState(params);
    }
  };

  updateStatusValue = () => {
    let { status } = this.state;
    let newVal = status === 1 ? 0 : 1;
    this.setState({ status: newVal });
  };

  saveUser = async () => {
    let user = {};
    let {
      id,
      identifier,
      displayName,
      role,
      password,
      cPassword,
      status,
      mayEditEmail
    } = this.state;
    let valid = true;
    let errorMsgs = [];
    if (typeof id === "string") {
      if (id.length > 5) {
        user._id = id;
      }
    }
    if (mayEditEmail) {
      if (identifier.length > 5) {
        user.identifier = identifier;
        valid = identifier.length > 5 && /^[^@ ]+@\w/.test(identifier);
        if (!valid) {
          errorMsgs.push("Please enter a valid email");
        }
      }
      if (password.length > 2) {
        let pwValid =
          password.length > 7 &&
          /^[a-z0-9.;,_-]+$/i.test(password) &&
          /[a-z]/i.test(password) &&
          /[0-9]/i.test(password);
        if (!pwValid) {
          errorMsgs.push(
            "Passwords must be at least 7 characters long with at least one letter and one numeral"
          );
        }
        if (password === cPassword) {
          user.password = password;
        } else {
          pwValid = false;
          errorMsgs.push("Your passwords must match");
        }
        if (!pwValid) {
          valid = false;
        }
      }
    }

    if (displayName.length > 2) {
      user.displayName = displayName.trim();
      if (user.displayName.length < 4) {
        valid = false;
        errorMsgs.unshift("Your display name must have at least 4 characters");
      }
    }

    if (role.length > 2) {
      user.role = role;
    }
    user.status = status;
    user.loginMode = "local";
    if (valid) {
      this.props.saveUser(user);
    } else {
      this.setState({
        errorMsgs: errorMsgs,
        showError: true
      });
    }
  };

  userData = () => {
    return this.state;
  };

  render() {
    let {
      displayName,
      identifier,
      role,
      status,
      loginMode,
      mayEditEmail,
      showError,
      errorMsgs
    } = this.state;
    let { closeEdit } = this.props;
    let roles = ["curator", "selector", "admin", "contributor"];
    let cls = status > 0 ? ["check"] : [];
    cls.push("square outline");
    let statusIconClassNames = cls.join(" ");

    return (
      <div className="user-edit-form">
        <div className="actions">
          <Icon className="save" onClick={this.saveUser}>
            <span className="label">Save</span>
          </Icon>
          <Icon className="close" onClick={closeEdit} />
        </div>
        <div className="inner-wrapper">
          {showError && (
            <ul className="error">
              {errorMsgs.map((msg, index) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
          )}
          <p>
            <input
              name="displayName"
              type="text"
              minLength={3}
              size={48}
              defaultValue={displayName}
              placeholder="Display name"
              required
              className="textfield textfield-long"
              onChange={this.updateValue}
            />
          </p>
          <p>
            <input
              name="identifier"
              type="email"
              defaultValue={identifier}
              minLength={6}
              size={32}
              placeholder="Email"
              required
              className="textfield textfield-medium"
              onChange={this.updateValue}
              disabled={!mayEditEmail}
            />
          </p>
          {mayEditEmail && (
            <p>
              <input
                name="password"
                type="password"
                minLength={6}
                size={16}
                defaultValue=""
                placeholder="Password"
                required
                className="textfield textfield-short"
                onChange={this.updateValue}
              />
              <input
                name="cPassword"
                type="password"
                minLength={6}
                size={16}
                defaultValue=""
                placeholder="Confirm password"
                required
                className="textfield textfield-short"
                onChange={this.updateValue}
              />
            </p>
          )}
          <p>
            {role === "contributor" ? (
              <span className="role">
                <strong>Role:</strong> <em>Contributor</em>
                <strong>Login mode:</strong> <em>{loginMode}</em>
              </span>
            ) : (
              <select name="role" value={role} onChange={this.updateValue}>
                {roles.map(roleKey => (
                  <option value={roleKey} key={roleKey}>
                    {roleKey}
                  </option>
                ))}
              </select>
            )}
          </p>
          <p className="checkbox-row status">
            <Icon
              className={statusIconClassNames}
              onClick={this.updateStatusValue}
            />
            <label htmlFor="user-status" onClick={this.updateStatusValue}>
              Active
            </label>
          </p>
        </div>
      </div>
    );
  }
}

export default UserEdit;
