import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class MenuListItem extends Component {
  constructor(props) {
    super(props)
  }

  isActive = (path) => {
    if (typeof path === 'string') {
      const section = '/' + path.split('/').find(part => part.length > 1);
      return this.props.current === section; 
    }
    return false
  }

  render() {
    const {to, children} = this.props
    const itemClasses = this.isActive(to)? 'active' : 'inactive'
    const linkClasses = to.replace(/^\//,'').replace('/','-');
    return (
      <li className={itemClasses}>
      <Link to={to} className={linkClasses}>
        {children}
      </Link>
    </li>
    )
  }
}

export default MenuListItem
