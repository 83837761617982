import React from "react";
import { Link } from "react-router-dom";
import { Segment, Container, Table, Icon } from "semantic-ui-react";
import moment from 'moment'
import { fetchLikes } from "../../modules/api";
import Photo from '../elements/photo'

class LikeList extends React.Component {
  state = {
    title: "Most liked images",
    likes: [],
    num: 0
  };

  async componentDidMount() {
    this.loadLikes();
  }

  loadLikes = async () => {
    let data = await fetchLikes();
    if (data.items) {
      this.setState({
        likes: data.items,
        num: data.num
      });
    }
  }

  render() {
    const { title, likes, num } = this.state;
    return (
      <Container className="likes-list">
        <h2>{title}</h2>
        <Segment.Group>
          {num > 0 && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Image</Table.HeaderCell>
                  <Table.HeaderCell># Likes</Table.HeaderCell>
                  <Table.HeaderCell>Details</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {likes.map((like, index) => (
                  <Table.Row key={index}>
                    <Table.HeaderCell><Link to={`/images/all/u--${like.user._id}#id--${like._id}`}><Photo img={like} width={400} height={400} crop="fit" /></Link></Table.HeaderCell>
                    <Table.Cell className="number large">{like.num}</Table.Cell>
                    <Table.Cell>
                      <h3>{like.caption}</h3>
                      <p>{like.description}</p>
                      <p>{like.user.displayName}</p>
                      <time>{moment(like.created).format("DD/MM/YYYY HH:mm")}</time>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Segment.Group>

      </Container>
    );
  }
}

export default LikeList