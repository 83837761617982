import config from "../config/config";

export const initCloudinary = async () => {
  let body = document.body;
  let scriptId = "cloudinary-upload-script";
  let st = document.getElementById(scriptId);

  let ts = 300;
  if (!st) {
    st = document.createElement("script");
    st.id = scriptId;
    st.src = "https://widget.cloudinary.com/v2.0/global/all.js";
    st.type = "text/javascript";
    body.appendChild(st);
    ts = 1200;
  }
};

const removeCloudinaryIframe = () => {
  let iframes = document.querySelectorAll("iframe");
  let loaded = false;
  let ifr = null;
  if (iframes.length) {
    for (let i = 0; i < iframes.length; i++) {
      ifr = iframes[i];
      if (ifr.src) {
        loaded = ifr.src.includes("cloudinary");
        if (loaded) {
          ifr.parentNode.removeChild(ifr);
          break;
        }
      }
    }
  }
};

export const openWidget = async callback => {
  if (window.cloudinary) {
    removeCloudinaryIframe();
    window.cloudinary.openUploadWidget(
      config.cloudinary.widget,
      (error, result) => {
        if (!error) {
          switch (result.event) {
            case "success":
              if (result.info) {
                callback(result);
              }
              break;
          }
        }
      }
    );
  }
};
