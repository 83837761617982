import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import Picture from "./picture";
import ImageEdit from "../gallery/edit";
import { Rnd } from "react-rnd";
import { addRemoveImageToCuration } from "../../modules/api";
import { addBodyClass } from "../../modules/utils";

class MainPhoto extends Component {
  state = {
    editMode: false,
    curations: [],
    showXL: false,
    position: { x: 0, y: 0 }
  };

  componentDidMount = () => {
    const { img, curations, mayAddToCurations } = this.props;
    if (img.editMode) {
      this.setEditMode();
    }
    if (mayAddToCurations) {
      if (curations instanceof Array) {
        let matchedCurations = curations
          .filter(cur => {
            let isIn = false;
            if (cur.images) {
              isIn =
                cur.images.filter(
                  ims => ims.image.toString() === img._id.toString()
                ).length > 0;
            }
            return isIn;
          })
          .map(cur => cur._id);

        this.setState({
          curations: matchedCurations
        });
      }
    }
  };

  setEditMode = () => {
    if (!this.state.editMode) {
      this.setState({
        editMode: true
      });
    }
  };

  updateImage = (data, user) => {
    this.props.updateImageData(data, user);
    this.setState({
      editMode: false
    });
  };

  isInCuration = curation => {
    return this.state.curations.indexOf(curation._id) >= 0;
  };

  addToCuration = curId => {
    let imgId = this.props.img._id;
    let { curations } = this.state;
    let curIndex = curations.indexOf(curId);
    let remove = curIndex >= 0;

    addRemoveImageToCuration(curId, imgId, remove).then(data => {
      let changed = false;
      let diff = 0;
      if (!remove && data.added) {
        curations.push(curId);
        changed = true;
        diff++;
      } else if (remove && data.removed) {
        let c = curations.splice(curIndex, 1);
        diff--;
        changed = true;
      }
      if (changed) {
        this.setState({
          curations: curations
        });
        this.props.updateCurations(curId, imgId, diff);
      }
    });
  };

  fileName = (img) => {
    if (img.uri) {
      return img.publicId.split('/').pop() + '.jpg'
    }
  }

  downloadSize = (img) => {
    if (img.uri) {
      return `Download full image: ${img.width} x ${img.height} px`
    }
  }

  toggleXL = e => {
    e.stopPropagation();
    let { showXL } = this.state;
    let newVal = showXL !== true;
    this.setState({
      showXL: newVal
    });
    addBodyClass("full-mode", newVal);
  };

  handleDrag = (e, d) => {
    let { x, y } = this.state.position;
    let bounds = document
      .querySelector(".main-images figure.active picture.xl img")
      .getBoundingClientRect();
    let nx = x + d.x;
    let ny = y + d.y;
    let maxNy = (bounds.height - window.innerHeight) / 2;
    let maxNx = (bounds.width - window.innerWidth) / 2;
    if (nx > maxNx) {
      nx = maxNx;
    } else if (nx < 0 - maxNx) {
      nx = 0 - maxNx;
    }
    if (ny > maxNy) {
      ny = maxNy;
    } else if (ny < 0 - maxNy) {
      ny = 0 - maxNy;
    }
    this.setState({
      position: {
        x: nx,
        y: ny
      }
    });
  };

  render() {
    const {
      img,
      sizes,
      xlSizes,
      editStatus,
      tags,
      curations,
      mayAddToCurations
    } = this.props;
    const { editMode, showXL, position } = this.state;
    let hasUser = false;
    if (img.user) {
      if (img.user.displayName) {
        hasUser = true;
      }
    }
    let cls = ["actions", img.status];
    const actionClassNames = cls.join(" ");
    let active = img.className === "active";
    cls = [img.className];
    if (editMode) {
      cls.push("edit-mode");
    }
    if (showXL) {
      cls.push("show-xl");
    }
    let wrapperClassNames = cls.join(" ");
    return (
      <figure className={wrapperClassNames}>
        {active && editMode && (
          <ImageEdit
            img={img}
            tags={tags}
            updateImage={this.updateImage.bind(this)}
          />
        )}
        <Picture img={img} sizes={sizes} className="large" />
        {showXL && (
          <Rnd position={position} onDragStop={this.handleDrag}>
            <Picture img={img} sizes={xlSizes} className="xl" />
          </Rnd>
        )}
        <div className="show-xl mid-overlay" onClick={this.toggleXL}>
          <Icon className="close" />
        </div>
        <div className={actionClassNames}>
          <Icon
            className="thumbs up"
            onClick={() => editStatus(img._id, "approved")}
            title="Approve"
          />
          <Icon
            className="hand point right outline"
            onClick={() => editStatus(img._id, "referred")}
            title="Refer"
          />
          <Icon
            className="thumbs down"
            onClick={() => editStatus(img._id, "rejected")}
            title="Reject"
          />
          {img.mayDelete && (
            <Icon
              className="trash"
              onClick={() => editStatus(img._id, "delete")}
              title="Delete"
            />
          )}
          <div className="status">{img.status}</div>
          {img.likes > 0 && <div className="num-likes" title="# of likes"><Icon className="heart" />{img.likes}</div>}
        </div>
        <figcaption>
          {img.tags && (
            <ul className="tags">
              {img.tags.map(t => (
                <li key={t._id}>{t.name}</li>
              ))}
            </ul>
          )}
          <p className="caption">{img.caption}</p>
          {img.hasDescription && (
            <p className="description">{img.description}</p>
          )}
          {img.hasLocation && (
            <p className="location">
              <Icon className="map marker alternate" />
              {img.location.placename}
            </p>
          )}
          {hasUser && (
            <p className="user-display-name">{img.user.displayName}</p>
          )}
          <p className="created datetime">Uploaded: {moment(img.created).format("DD/MM/YYYY HH:mm")}</p>
          <Icon
            className="edit outline"
            onClick={this.setEditMode}
            title="Edit"
          />
          <Icon
            className="cloud download">
            <a href={img.uri} target="_blank" title={this.downloadSize(img)} download={this.fileName(img)}></a>
          </Icon>
        </figcaption>
        {mayAddToCurations && (
          <ul className="curations">
            {curations.map(cur => (
              <li key={cur._id} onClick={() => this.addToCuration(cur._id)}>
                <span className="title">{cur.title}</span>
                <em className="num">{cur.images.length}</em>
                {this.isInCuration(cur, img._id) && <Icon className="check" />}
              </li>
            ))}
          </ul>
        )}
      </figure>
    );
  }
}

export default MainPhoto;
