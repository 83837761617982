import { callbackify } from "util";

export const isNumeric = (num) => {
	let dt = typeof num;
	if (num !== null && num !== undefined && (dt === 'number' || dt === 'string')) {
		return !isNaN(parseFloat(num)) && isFinite(num);	
	} else {
		return false;
	}
}


export const matchInt = (param, defaultValue) => {
	if (!isNumeric(param)) {
    return defaultValue;
  } else {
    return parseInt(param);
  }
}

export const addBodyClass = (className, addMode) => {
	let cl = document.body.classList
	let exists = cl.contains(className)
	if (addMode !== false) {
		if (!exists) {
			cl.add(className)
		}
	} else {
		if (exists) {
			cl.remove(className)
		}
	}
}

export const removeBodyClass = (className) => {
	addBodyClass(className, false)
}
