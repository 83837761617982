import React from "react";
import { Segment, Container, Table, Icon } from "semantic-ui-react";
import { fetchCurations, fetchUser, deleteCuration } from "../../modules/api";
import { Link } from "react-router-dom";

class CurationList extends React.Component {
  state = {
    title: "Curations",
    curations: [],
    numCurations: 0,
    currUser: null
  };

  async componentDidMount() {
    let user = await fetchUser();
    if (user) {
      this.setState({
        currUser: user
      });
      this.loadCurations(user);
    }
  }

  loadCurations = async user => {
    let data = await fetchCurations();
    switch (user.role) {
      case "admin":
      case "selector":
        break;
      default:
        let uid = user._id.toString();
        data.items = data.items.filter(cu => cu.user._id.toString() === uid);
        break;
    }
    if (data.items) {
      this.setState({
        curations: data.items,
        numCurations: data.items.length
      });
    }
  };

  deleteCuration = curId => {
    deleteCuration(curId).then(data => {
      if (data.valid) {
        let editedCurations = this.state.curations.filter(c => c._id !== curId);
        this.setState({
          curations: editedCurations,
          numCurations: editedCurations.length
        });
      }
    });
  };

  render() {
    const { title, curations, numCurations } = this.state;
    return (
      <Container className="curations-list">
        <Segment.Group>
          <h2>{title}</h2>
          <div className="actions">
            <Link to="/curation/edit/new">
              <Icon className="add" />
            </Link>
          </div>
          {numCurations > 0 && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Curator</Table.HeaderCell>
                  <Table.HeaderCell># images</Table.HeaderCell>
                  <Table.HeaderCell>Edit</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {curations.map((cur, index) => (
                  <Table.Row key={index}>
                    <Table.HeaderCell>{cur.title}</Table.HeaderCell>
                    <Table.HeaderCell>{cur.user.displayName}</Table.HeaderCell>
                    <Table.Cell>
                      {cur.numSelected} / {cur.num}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/curation/edit/${cur._id}`}>
                        <Icon className="edit" />
                      </Link>
                      {cur.images.length < 1 && (
                        <Icon
                          className="delete"
                          onClick={() => this.deleteCuration(cur._id)}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Segment.Group>
      </Container>
    );
  }
}

export default CurationList;
