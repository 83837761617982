const localStorageSupported = () => {
	try {
    return 'localStorage' in window && window['localStorage'] !== null;
  } catch (e) {
    return false;
  }
}
	
export const toLocal = (key, data) => {
	if (localStorageSupported()) {
		let ts = Date.now() / 1000,sd = ts + ':';
		if (typeof data === 'object') {
			sd += 'obj:' + JSON.stringify(data);
		} else {
			sd += 'sca:' + data;
		}
		localStorage.setItem(key, sd);
	}
}
	
export const fromLocal = (key, maxAge) => {
	let ts = Date.now() / 1000;
	let obj = {
		expired:true,
		valid:false
	};
	let data = localStorage.getItem(key);
	if (localStorageSupported()) {
		if (data) {
			let parts = data.split(':');
			if (parts.length > 2) {
				obj.ts = parts.shift();
				obj.ts = obj.ts - 0;
				obj.type = parts.shift();
				obj.data = parts.join(':');
				if (obj.type === 'obj') {
					obj.data = JSON.parse(obj.data);
				}
				obj.valid = true;
				if ((ts - maxAge) > obj.ts) {
					obj.expired = false;
				}
			}
		}
	}
	return obj;
}