import React, { Component } from 'react'
import config from "../../config/config";
class Picture extends Component {
  constructor(props) {
    super(props)
  }


  urlTransform = (img, size, density) => {
    if (density) {
      density = ' ' + density;
    } else {
      density = '';
    }
    const c = config.cloudinary;
    return `${c.baseUrl}/${c.widget.cloudName}/image/upload/f_auto,q_auto/c_fit,h_${size.height},q_90,w_${size.width}/v1/${img.publicId}${density}`
  }

  mediaTransform = (size) => {
    let comps = [];
    if (size.min) {
      comps.push(`(min-width: ${size.min}px)`);
    }
    if (size.max) {
      comps.push(`(max-width: ${size.max}px)`);
    }
    return comps.join(' and ');
  }

  render() {
    const {sizes, img, className} = this.props;
    const defaultSize = sizes.find(size => size.default);
    let cls = ['image-set']
    if (className) {
      cls.push(className);
    }
    let wrapperClasses = cls.join(' ');
    return (
      <picture className={wrapperClasses}>
        {sizes.map((size, index) => (
          <source
            srcSet={this.urlTransform(img, size, '1x')}
            media={this.mediaTransform(size)}
            key={index} />
        ))}
      {img.hasPublicId ? (
        <img
          src={this.urlTransform(img, defaultSize)}
        />
      ) : (
        <img src={img.uri} />
      )}
      
    </picture>
    )
  }
}

export default Picture
