import React, { Component } from 'react'

class Footer extends Component {

  render() {
    const { title, subtitle, subtitleLink } = this.props.data
    return (
      <footer className="footer">
        <section className="inner">
          <div className="inner-wrapper">
            <div className="inner">
              <h2 className="title">{title}</h2>
              <h3 className="subtitle"><a href={subtitleLink} target="_blank" rel="noopener noreferrer">{subtitle}</a></h3>
            </div>
          </div>
        </section>
      </footer>
    )
  }
}

export default Footer
