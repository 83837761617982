import React from "react";
import { Segment, Container, Icon } from "semantic-ui-react";
import { WithContext as ReactTags } from "react-tag-input";
import moment from "moment";
import {
  fetchImages,
  fetchTags,
  fetchArtists,
  updateImageStatus,
  deleteImage,
  fetchCurations,
  fetchUser
} from "../../modules/api";
import Photo from "../elements/photo";
import MainPhoto from "../elements/main-photo";
import Paginator from "../elements/paginator";
import config from "../../config/config";

class Gallery extends React.Component {
  mounted = false;
  loadTimeout = null;

  state = {
    title: "Gallery",
    images: [],
    numImages: 0,
    tags: [],
    numTags: 0,
    artists: [],
    numArtists: 0,
    numCols: 6,
    main: [],
    currMainIndex: -1,
    showMain: false,
    selectedTags: [],
    activeFilter: "",
    activeStatus: "all",
    statusOpts: [],
    message: "",
    showMessage: false,
    totalImages: 0,
    loadOffset: 0,
    page: 0,
    numPages: 1,
    currUser: null,
    mayAddToCurations: false,
    curations: [],
    numCurations: []
  };

  async componentWillMount() {
    this.parseParams();
    let user = await fetchUser();
    if (user) {
      if (user.role) {
        let mayAddToCurations = false;
        switch (user.role) {
          case "admin":
          case "selector":
            mayAddToCurations = true;
            break;
        }
        this.setState({
          currUser: user,
          mayAddToCurations: mayAddToCurations
        });
      }
    }
  }

  async componentDidMount() {
    if (!this.mounted) {
      let pn = this.matchPage();
      this.setState({ page: pn });
      this.loadTimeout = setTimeout(() => this.loadImages(), 125);
      let tagData = await fetchTags();
      this.setState({
        tags: tagData.items,
        numTags: tagData.num
      });
      let aData = await fetchArtists();
      this.setState({
        artists: aData.items,
        numArtists: aData.num
      });
      this.handleStateOpts();
      window.addEventListener("keydown", this.handleKeyDown);

      this.mounted = true;
      if (this.state.mayAddToCurations) {
        setTimeout(() => {
          fetchCurations().then(data => {
            if (data.items) {
              this.setState({
                curations: data.items,
                numCurations: data.items.length
              });
            }
          });
        }, 500);
      }
      this.loadSelectedImage();
    }
  }

  matchPage = () => {
    let { location } = this.props;
    let pn = 0;
    if (location.search) {
      let strPn = location.search
        .split("page=")
        .pop()
        .split("&")
        .shift();
      if (strPn) {
        pn = parseInt(strPn);
      }
    }
    return pn;
  };

  componentDidUpdate(prevProps, prevState) {
    let { activeStatus } = this.state;
    let pn = this.matchPage();

    let prevStatus = prevState.activeStatus;

    if (prevStatus !== activeStatus || pn !== prevState.page) {
      this.setState({ page: pn });
      this.loadImages(pn < 1);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
  }

  parseParams = () => {
    if (this.props.location) {
      if (this.props.location.pathname) {
        let parts = this.props.location.pathname.split("/images/");
        let status = "all";
        let category = "all";
        if (parts.length > 1) {
          parts = parts.pop().split("/");
          if (parts.length > 0) {
            status = parts.shift();
            if (parts.length > 0) {
              category = parts.shift();
            }
          }
        }
        this.setState({
          activeFilter: category,
          activeStatus: status
        });
      }
    }
  };

  updateImageData = (edited, user) => {
    if (edited._id) {
      let keys = Object.keys(edited);
      let imgs = this.state.images;
      let img = imgs.find(im => im._id === edited._id);
      if (img) {
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          switch (k) {
            case "caption":
            case "description":
            case "location":
              img[k] = edited[k];
              break;
            case "tags":
              if (edited[k] instanceof Array) {
                let allTs = this.state.tags;
                let ts = edited[k].map((tn, ti) => {
                  let tg = allTs.find(
                    t => t.name.toLowerCase() === tn.toLowerCase()
                  );
                  if (tg) {
                    return tg;
                  } else {
                    return {
                      _id: "i_" + ti.toString(),
                      name: tn
                    };
                  }
                });
                img[k] = ts;
              }
              break;
            case "user":
              img.user = user;
              break;
          }
        }
        img = this.mapImage(img);
        let editedImgs = imgs.map(im => {
          if (im._id === img._id) {
            return img;
          } else {
            return im;
          }
        });
        this.setState({
          images: editedImgs
        });
      }
    }
  };

  handleKeyDown = e => {
    switch (e.which) {
      case 39:
      case 37:
        if (!e.metaKey && !e.shiftKey && this.state.showMain) {
          let tn = e.target.tagName.toLowerCase();
          switch (tn) {
            case "input":
            case "textarea":
              break;
            default:
              if (e.which === 39) {
                if (this.state.currMainIndex < this.state.numImages - 1) {
                  this.showNext();
                }
              } else {
                if (this.state.currMainIndex > 0) {
                  this.showPrev();
                }
              }
              break;
          }
        }
        break;
      case 27:
        if (this.state.showMain) {
          this.unsetMain();
        }
        break;
    }
  };

  loadSelectedImage = () => {
    if (window.location.hash.length > 5) {
      if (window.location.hash.indexOf('#id--') === 0) {
        let imgId = window.location.hash.split('--').pop();
        let selImg = this.state.images.find(img => img._id.toString() === imgId);
        if (selImg) {
          this.setMain(selImg);
        }
      }
    }
  };

  editStatus = async (id, status) => {
    let data = {
      valid: false
    };
    if (id) {
      switch (status) {
        case "approved":
        case "referred":
        case "rejected":
          data = await updateImageStatus(id, status);
          break;
        case "delete":
          let img = this.state.images.find(im => im._id === id);
          if (img) {
            this.deleteImage(img);
          }
          break;
      }
    }
    if (data.valid) {
      let imgs = this.state.images.map(img => {
        if (img._id === data._id) {
          img.status = status;
        }
        return this.mapImage(img);
      });
      this.setState({
        images: imgs
      });
    }
  };

  deleteImage = async img => {
    if (img.user) {
      deleteImage(img._id).then(res => {
        if (res.valid) {
          this.setState({
            message: `Image (${img.caption}) removed`,
            showMessage: true
          });
          setTimeout(() => this.removeImage(img), 1000);
        }
      });
    }
  };

  removeImage = img => {
    let imgs = this.state.images.filter(im => im._id !== img._id);
    this.setState({
      images: imgs,
      numImages: imgs.length,
      hasSelection: false,
      showMessage: false
    });
  };

  loadImages = async init => {
    let { activeStatus, activeFilter } = this.state;
    let total = 0;
    let perLoad = config.gallery.perLoad;
    let loadOffset = init === true ? 0 : this.state.page * perLoad;
    if (!activeStatus) {
      activeStatus = "all";
    }
    if (!activeFilter) {
      activeFilter = "all";
    }
    let data = await fetchImages(
      activeStatus,
      loadOffset,
      perLoad,
      activeFilter
    );
    const imgs = data.items
      .filter(img => img.hasPublicId)
      .map(img => this.mapImage(img));
    if (data.total) {
      total = data.total;
    }
    this.setState({
      images: imgs,
      numImages: imgs.length,
      total: total,
      numPages: data.pages
    });
  };

  mainImageClassNames = (index, defIndex) => {
    let cls = index === defIndex ? ["active"] : ["inactive"];
    if (index === defIndex - 1) {
      cls.push("prev");
    } else if (index === defIndex + 1) {
      cls.push("next");
    } else if (index !== defIndex) {
      cls.push("off");
    }
    return cls.join(" ");
  };

  setMain = async (targetImg, editMode) => {
    let index = this.state.images.findIndex(im => im._id === targetImg._id);
    if (index >= 0) {
      targetImg.editMode = editMode === true;
      let startIndex = index > 0 && this.state.numImages > 1 ? index - 1 : 0;
      let numMainImgs = this.state.numImages > 2 ? 3 : this.state.numImages;
      let endIndex = startIndex + numMainImgs - 1;
      let lastIndex = this.state.numImages - 1;
      if (endIndex > lastIndex) {
        let diff = endIndex - lastIndex;
        endIndex = lastIndex;
        startIndex -= diff;
      }
      let imgs = [];
      for (let i = startIndex; i <= endIndex; i++) {
        imgs.push(this.state.images[i]);
      }
      let defIndex = 1;

      if (index === 0) {
        defIndex = 0;
      } else if (index === lastIndex) {
        defIndex = numMainImgs - 1;
      }
      imgs = imgs.map((img, si) => {
        img.className = this.mainImageClassNames(si, defIndex);
        return img;
      });
      this.setState({
        main: imgs,
        showMain: true,
        currMainIndex: index
      });
    }
  };

  mapImage = img => {
    switch (img.status) {
      case "rejected":
        img.mayDelete = true;
        break;
      default:
        img.mayDelete = false;
        break;
    }
    switch (img.status) {
      case "approved":
        img.statusClassNames = "thumbs up";
        break;
      case "referred":
        img.statusClassNames = "hand point right";
        break;
      case "rejected":
        img.statusClassNames = "thumbs down";
        break;
      default:
        img.statusClassNames = "circle notched";
        break;
    }
    img.statusClassNames += " status-icon";

    img.hasLocation = false;
    if (img.location) {
      if (img.location.placename) {
        img.hasLocation = true;
      }
    }
    img.hasDescription = false;
    if (img.description) {
      if (typeof img.description === "string") {
        img.hasDescription = img.description.length > 3;
      }
    }
    img.selected = false;
    return img;
  };

  addToMain = (index, dir) => {
    if (index >= 0 && index < this.state.images.length) {
      let img = this.state.images[index];
      let imgs = this.state.main;
      img.className = "inactive off";
      if (dir > 0) {
        imgs.push(img);
      } else {
        imgs.unshift(img);
      }
      this.setState({
        main: imgs
      });
    }
  };

  unsetMain = () => {
    this.setState({
      showMain: false
    });
  };

  showNext = prev => {
    let dir = prev === true ? -1 : 1;
    const lastIndex = this.state.main.length - 1;
    const currIndex = this.state.main.findIndex(
      img => img.className === "active"
    );
    const currImg = this.state.main[currIndex];
    const targetIndex = currIndex + dir;
    if (currImg && this.state.main.length > 0) {
      if (lastIndex >= 0) {
        const imgs = this.state.main.map((img, si) => {
          img.className = this.mainImageClassNames(si, targetIndex);
          return img;
        });
        this.setState({
          showMain: imgs,
          currMainIndex: this.state.currMainIndex + dir
        });
        let endImg = dir > 0 ? this.state.main[lastIndex] : this.state.main[0];
        let nextIndex =
          this.state.images.findIndex(img => img._id === endImg._id) + dir;
        setTimeout(() => {
          this.addToMain(nextIndex, dir);
        }, 300);
      }
    }
  };

  showPrev = () => {
    this.showNext(true);
  };

  showNextIcon = () => {
    return this.state.currMainIndex < this.state.numImages - 1;
  };

  showPrevIcon = () => {
    return this.state.currMainIndex > 0;
  };

  handleStateOpts = () => {
    const opts = ["uploaded", "referred", "rejected", "unapproved", "approved", "all"];
    let stOpts = opts.map(key => {
      let name = '';
      switch (key) {
        case 'uploaded':
          name = 'new';
          break;
        case 'unapproved':
          name = 'all unapproved';
          break;
        default:
          name = key;
          break;
      }
      return {
        key: key,
        name: name,
        className: key === this.state.activeStatus ? "active" : "inactive"
      };
    });
    this.setState({
      statusOpts: stOpts
    });
  };

  updateUrl = () => {
    let { activeStatus, activeFilter } = this.state;
    if (!activeFilter) {
      activeFilter = "all";
    }
    let path = `/images/${activeStatus}/${activeFilter}`;
    this.props.history.push(path);
  };

  filterByTag(tag) {
    if (typeof tag === "string") {
      tag = {
        slug: "all",
        name: "All"
      };
    }
    if (tag.slug !== this.state.activeFilter) {
      this.setState({
        activeFilter: tag.slug,
        filterName: tag.name,
        loadOffset: 0
      });
      setTimeout(() => this.updateUrl(), 50);
      setTimeout(() => this.loadImages(), 100);
    } else {
      this.setState({
        loadOffset: 0
      });
      setTimeout(() => this.loadImages(), 75);
    }
  }

  filterByUser(user) {
    let ref = "u--" + user.id;
    if (ref !== this.state.activeFilter) {
      this.setState({
        activeFilter: ref,
        filterName: user.displayName
      });
      setTimeout(() => this.updateUrl(), 50);
      setTimeout(() => this.loadImages(), 100);
    }
  }

  filterByStatus = status => {
    if (status !== this.state.activeStatus) {
      this.setState({
        activeStatus: status
      });
      setTimeout(() => {
        this.updateUrl();
        //this.handleStateOpts();
      }, 100);
    }
  };

  updateStatusFilter = e => {
    this.filterByStatus(e.target.value)
  };

  selectTag = tag => {
    if (tag !== null && typeof tag === "object") {
      let matched = false;
      switch (tag.type) {
        case "tag":
          this.filterByTag(tag);
          matched = true;
          break;
        case "artist":
          this.filterByUser(tag);
          matched = true;
          break;
      }
      if (matched) {
        this.setState({
          selectedTags: [tag]
        });
      }
    }
  };

  deleteTag = e => {
    let valid = true;
    if (e) {
      if (e.target) {
        valid = false;
        if (e.target.classList.contains("ReactTags__tag")) {
          valid = true;
        }
      }
    }
    if (valid) {
      this.filterByTag("all");
      this.setState({
        selectedTags: []
      });
    }
  };

  handleSuggestions = (txt, suggestions) => {
    let lcTxt = txt.toLowerCase();
    return suggestions.filter(suggestion => {
      switch (suggestion.type) {
        case "tag":
          return suggestion.name.toLowerCase().includes(lcTxt);
        default:
          let matched = suggestion.name.toLowerCase().includes(lcTxt);
          if (!matched) {
            if (suggestion.email) {
              matched = suggestion.email.toLowerCase().includes(lcTxt);
            }
          }
          return matched;
      }
    });
  };

  selectedClassNames = img => {
    let cls = ["square", "outline"];
    if (img.selected) {
      cls.unshift("check");
    }
    return cls.join(" ");
  };

  selectAll = newMode => {
    let imgs = this.state.images.map(im => {
      im.selected = newMode;
      return im;
    });
    this.setState({ images: imgs });
  };

  bulkApprove = () => {
    this.bulkUpdate("approved");
  };

  bulkReject = () => {
    this.bulkUpdate("rejected");
  };

  bulkUpdate = status => {
    let ids = this.state.images.filter(im => im.selected).map(im => im._id);
    updateImageStatus(ids, status).then(d => {
      if (d.valid) {
        let imgs = this.state.images.map(im => {
          if (ids.indexOf(im._id) >= 0) {
            im.status = status;
            im.selected = false;
          }
          return this.mapImage(im);
        });
        this.setState({ images: imgs });
        this.forceUpdate();
      }
    });
  };

  selectPhoto = img => {
    img.selected = img.selected !== true;
    this.forceUpdate();
  };

  combineTags = () => {
    let { artists, tags } = this.state;
    return artists.concat(tags).map(item => {
      let txt = "";
      let name = "";
      let sType = "";
      let email = "";
      if (item.displayName) {
        sType = "artist";
        txt = `${item.displayName}, ${item.identifier}`;
        name = item.displayName;
        email = item.identifier;
      } else if (item.name) {
        sType = "tag";
        txt = `${item.name} [${sType}]`;
        name = item.name;
      }
      if (sType) {
      }
      txt += " (" + item.numImages + ")";
      let slug = item.slug ? item.slug : item._id;

      return {
        id: item._id,
        text: txt,
        slug: slug,
        name: name,
        type: sType,
        email: email
      };
    });
  };

  selectedStats = () => {
    let selected = this.state.images.filter(im => im.selected);
    let numApproved = 0;
    let total = 0;
    if (selected instanceof Array && selected.length > 0) {
      numApproved = selected.filter(im => im.status === "approved").length;
      total = selected.length;
    }
    return {
      total: total,
      approved: numApproved,
      unapproved: total - numApproved
    };
  };

  updateDayFilter = e => {
    let update = true
    let sd = 'all'
    if (e.target.value) {
      sd = e.target.value
      if (/^20\d\d-\d\d-\d\d/.test(sd)) {
        update = true
      } else if (sd.length < 1) {
        update = true
        sd = 'all'
      } else {
        update = false
      }
    }
    if (update) {
      this.setState({
        activeFilter: sd,
        activeStatus: 'all'
      });
      setTimeout(() => this.loadImages(), 100);
    }
  }

  resetDay = () => {
    this.setState({
      activeFilter: 'all'
    });
    setTimeout(() => this.loadImages(), 100);
  }

  updateCurations = (curId, imgId, diff) => {
    let { curations } = this.state;
    let cur = curations.find(c => c._id === curId);
    if (cur) {
      if (diff > 0) {
        cur.images.push({
          image: imgId,
          selected: false
        });
      } else if (diff < 0) {
        cur.images = cur.images.filter(ims => ims.image.toString() !== imgId);
      }
      this.setState({ curations: curations });
    }
  };

  render() {
    let {
      title,
      images,
      numImages,
      activeFilter,
      activeStatus,
      main,
      showMain,
      statusOpts,
      message,
      showMessage,
      tags,
      total,
      page,
      numPages,
      mayAddToCurations,
      curations,
      numCurations
    } = this.state;
    let { selectedTags } = this.state;
    let day = "";
    let hasDay = false
    if (/^20\d\d\-\d\d-\d\d/.test(activeFilter)) {
      day = activeFilter
      hasDay = true
    }
    const mixedTags = this.combineTags();
    let numSelected = images.filter(im => im.selected).length;
    let selectAllClassName = "square outline";
    let bulkSelect = numSelected < numImages / 2;
    if (bulkSelect) {
      selectAllClassName += " check";
    }
    let selectedStats = this.selectedStats();
    let showApproveSelected =
      selectedStats.unapproved > 0 && activeStatus !== "approved";
    let showRejectSelected =
      selectedStats.approved > 0 && selectedStats.total < numImages / 2;

    let cls = ["top-bar"];
    if (!activeFilter) {
      activeFilter = "all";
    }
    if (activeFilter !== "all") {
      cls.push("is-filtered");
    }
    if (activeFilter) {
      if (activeFilter !== "all") {
        const slug = activeFilter.split("--").pop();
        let tag = mixedTags.find(t => t.slug === slug);
        if (tag) {
          selectedTags = [tag];
        }
      }
    }
    let topBarClassNames = cls.join(" ");
    cls = ["contributor-images"];
    if (showMain) {
      cls.push("show-main");
    } else {
      cls.push("thumbnails");
    }
    if (showMessage) {
      cls.push("show-message");
    }
    let mainClassNames = cls.join(" ");
    let showPaginator = numPages > 1;
    return (
      <Container className={mainClassNames}>
        <div className={topBarClassNames}>
          <h1 onClick={() => this.filterByTag("all")}>
            <span className="title">{title}</span>
            <Icon className="refresh" />
            <em className="num-images">
              {numImages} of {total}
            </em>
          </h1>
          <ReactTags
            tags={selectedTags}
            suggestions={mixedTags}
            className="textfield textfield-long"
            handleAddition={this.selectTag}
            handleDelete={this.deleteTag}
            allowDragDrop={false}
            placeholder="Search"
            handleFilterSuggestions={this.handleSuggestions}
            minQueryLength={1}
            inline
          />

          {statusOpts.length > 0 && (
            <select
              name="status_options"
              value={activeStatus}
              onChange={this.updateStatusFilter}
            >
              {statusOpts.map(opt => (
                <option value={opt.key} key={opt.key}>
                  {opt.name}
                </option>
              ))}
            </select>
          )}
          {hasDay ? (<label onClick={this.resetDay} title="Show all dates">Day</label>) : (<label title="Filter by a specfic date (GMT)">Day</label>)}
          <input name="start" className="day" type="date" onChange={this.updateDayFilter} onClick={this.handleDayWidget} />
          {hasDay && <Icon className="close" onClick={this.resetDay} title="Show all dates" />}
          <div className="bulk-actions">
            <span className="num-selected">{selectedStats.total}</span>
            {showApproveSelected && (
              <Icon
                className="thumbs up"
                title="Approve all selected images"
                onClick={this.bulkApprove}
              />
            )}
            {showRejectSelected && (
              <Icon
                className="thumbs down"
                title="Reject all selected images"
                onClick={this.bulkReject}
              />
            )}
            <Icon
              className={selectAllClassName}
              onClick={() => this.selectAll(bulkSelect)}
            />
            <a href="/api/admin/works-csv/approved" title="Download all approved images as a CSV">
              <Icon
                className="file excel outline"
              />
              <span className="label">CSV</span>
            </a>
          </div>
        </div>
        <div className="message-pane">{message}</div>
        <Segment.Group id="image-list" horizontal>
          {numImages > 0 &&
            images.map((img, index) => (
              <Segment as="figure" key={img._id} className={img.status}>
                <Photo
                  img={img}
                  width={400}
                  height={400}
                  crop="fill"
                  className="preview"
                />
                <div
                  className="photo-overlay"
                  onClick={() => this.setMain(img)}
                />
                <Icon
                  className={this.selectedClassNames(img)}
                  onClick={() => this.selectPhoto(img)}
                />
                <Icon className={img.statusClassNames} title={img.status} />
                <figcaption onClick={() => this.setMain(img, true)}>
                  <p className="caption">{img.caption}</p>
                  <p className="full-name">{img.user.displayName}</p>
                  <p className="created datetime">{moment(img.created).format("DD/MM/YYYY HH:mm")}</p>
                </figcaption>
              </Segment>
            ))}
        </Segment.Group>
        {showMain && (
          <Segment.Group className="main-images">
            <Icon className="close" onClick={this.unsetMain} />
            {this.showPrevIcon() && (
              <Icon className="arrow left" onClick={this.showPrev} />
            )}
            {main.map((mainImg, si) => (
              <MainPhoto
                img={mainImg}
                sizes={config.imageStyles.large.sizes}
                xlSizes={config.imageStyles.xlarge.sizes}
                key={'main_' + mainImg._id}
                className={mainImg.className}
                editStatus={this.editStatus}
                tags={tags}
                updateImageData={this.updateImageData.bind(this)}
                curations={curations}
                mayAddToCurations={mayAddToCurations}
                updateCurations={this.updateCurations}
              />
            ))}
            {this.showNextIcon() && (
              <Icon className="arrow right" onClick={this.showNext} />
            )}
          </Segment.Group>
        )}
        <div className="backdrop" onClick={this.unsetMain} />
        {showPaginator && (
          <Paginator linkPath="/images" numPages={numPages} page={page} />
        )}
      </Container>
    );
  }
}

export default Gallery;
